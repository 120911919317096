<template>
  <div class="main-container">
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <!-- Loading indicator -->
    <v-progress-linear v-if="isLoading" indeterminate rounded style="max-width: 300px; margin:15px;" color="primary"
      class="loading-bar"></v-progress-linear>
    <div v-else>
      <div v-if="scorecards && scorecards.length > 0">
        <div v-for="(scorecard, index) in scorecards" :key="index">
          <div class="chapters-container">
            <v-card rounded="xl" style="width: 100%; padding:40px; text-align: left;">
              <div style="text-align: left;">
                <v-breadcrumbs style="margin-left: 15px;">
                  <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t("settings.title")
                    }}</v-breadcrumbs-item>
                  >
                  <v-breadcrumbs-item to="/:tenant/settings/conversationtypes" color="primary">{{
                    $t("settings.conversationtypes") }}</v-breadcrumbs-item>
                  >
                  <v-breadcrumbs-item>{{ $t("scorecardEditNew.scorecard") }}: {{ name ||
                    $t("scorecardEditNew.newScorecard") }}</v-breadcrumbs-item>
                </v-breadcrumbs>
                <label for="scorecardName" style="font-size: 1.5em; font-weight: bold; margin-left:30px">{{
                  $t("scorecardEditNew.scorecard") }}: </label>
                <input id="scorecardName" label="Scorecard name" type="text" :value="name"
                  @input="name = $event.target.value" @blur="saveToSupabase"
                  style="width: 40%; margin: auto; color: #5932EA; border: none; border-bottom: 1px solid; outline: none; padding-top: 10px; font-size: 1.5em; font-weight: bold;">
              </div>
              <!-- Chapters and topics -->
              <div v-for="(chapter, chapterIndex) in scorecard.chapters" :key="chapterIndex" class="chapter">
                <div v-if="chapter.editing" class="chapter-editing">
                  <input v-model="chapter.chapter_name" @keyup.enter="saveChapterName(index, chapterIndex)"
                    @blur="saveChapterName(index, chapterIndex)" class="chapter-input"
                    :ref="`chapterInputField${chapterIndex}`">

                </div>
                <h2 v-else @click="editChapterName(index, chapterIndex)" class="chapter-editing">
                  {{ chapter.chapter_name }}
                  <button @click="deleteChapter(index, chapterIndex)" class="trash-button"
                    style="background: none; border: none; padding: 0px; cursor: pointer; position: relative; z-index: 9;">
                    &#128465;
                  </button>
                </h2>

                <div v-for="(topic, topicIndex) in chapter.topics" :key="topicIndex">
                  <div class="topic-card"
                    :class="{ 'active-topic-card': selectedChapterIndex === chapterIndex && selectedTopicIndex === topicIndex }"
                    @click.stop="selectTopic(chapterIndex, topicIndex)" :value="topic.topic_name" v-if="topic">
                    <v-text-field v-model="topic.topic_name" @keyup.enter="saveTopic(index, chapterIndex, topicIndex)"
                      @blur="saveTopic(index, chapterIndex, topicIndex)" class="topic-input" density="compact"
                      variant="underlined" :ref="`topicInputField${topicIndex}`" v-if="topic.editing">
                    </v-text-field>
                    <div v-else class="topic-container" @click.stop="editTopicName(index, chapterIndex, topicIndex)">
                      <v-text-field readonly :value="topic.topic_name" prepend-icon="mdi-cog" variant="underlined"
                        density="compact" @click:prepend="showDetailsContainer = true; selectedTopic = topic;"
                        append-icon="mdi-delete" @click:append="deleteTopic(index, chapterIndex, topicIndex)"
                        v-if="topic">
                      </v-text-field>
                    </div>
                  </div>

                  <div class="details-container" v-show="showDetailsContainer && selectedTopic === topic">
                    <v-combobox v-if="selectedChapterIndex === chapterIndex && selectedTopicIndex === topicIndex"
                      v-model="topic.followUp" clearable variant="solo" chips multiple label="Tracking"
                      :items="['Thema angesprochen', 'Angebot', 'Folgetermin', 'Bestandsvertrag', 'Kein Interesse']"
                      @change="handleFollowUpChange(index, chapterIndex, topicIndex)"></v-combobox>

                    <label>{{ $t("scorecardEditNew.loveWords") }}</label>
                    <v-text-field v-model="topic.loveWords"></v-text-field>
                    <label>{{ $t("scorecardEditNew.hateWords") }}</label>
                    <v-text-field v-model="topic.wordsToAvoid"></v-text-field>
                    <label>{{ $t("scorecardEditNew.crossSellingAvenue") }}</label>
                    <v-text-field v-model="topic.crossSellingAvenue"></v-text-field>
                    <label>{{ $t("scorecardEditNew.example") }}</label>
                    <v-text-field v-model="topic.example"></v-text-field>
                    <v-btn
                      @click="applyToTopic(topicDetails, index, chapterIndex, topicIndex); showDetailsContainer = false"
                      class="py-2 text-none" color="#5932ea" elevation="0" style="color: white;">{{
                        $t("scorecardEditNew.save") }}</v-btn>
                  </div>
                </div>
                <v-btn @click.stop="addTopic(index, chapterIndex)" class="py-2 text-none" color="#5932ea" elevation="0"
                  style="color: white; margin-right: 90%; margin-left: 10px">{{ $t("scorecardEditNew.addTopic") }}
                </v-btn>
              </div>
              <v-btn @click="addChapter" class="py-2 text-none" color="#5932ea" variant="outlined" prepend-icon=""
                elevation="0" style="padding: 10px; margin-right: 90%; margin-left: 10px; margin-bottom: 10px;">{{
                  $t("scorecardEditNew.addChapter") }}</v-btn>

            </v-card>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{ $t("scorecardEditNew.noScorecardsAvailable") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { supabase } from '@/supabase.js';

export default {
  name: 'view-scorecard-edit',
  data() {
    return {
      scorecards: [],
      selectedScorecardIndex: null,
      selectedChapterIndex: null,
      selectedTopicIndex: null,
      addingChapter: false,
      showDetailsContainer: false,
      selectedTopic: null,
      isLoading: true,
      showErrorSnackbar: false,
      errorMessage: ''
    }
  },

  async mounted() {
    try {
      await this.loadFromSupabase();
      // Set the selected indices to the first scorecard, chapter, and topic if they exist
      if (this.scorecards.length > 0) {
        this.selectedScorecardIndex = 0;
        if (this.scorecards[0].chapters.length > 0) {
          this.selectedChapterIndex = 0;
          if (this.scorecards[0].chapters[0].topics.length > 0) {
            this.selectedTopicIndex = 0;
          }
        }
        this.isLoading = false;
      }
    } catch (e) {
      this.handleError(e.message);
    }
  },

  methods: {
    //   sanitizeString(str) {
    //     if (Array.isArray(str)) {
    //       return str.map(item => this.sanitizeString(item)); // Recursively sanitize each item if it's an array
    //   } else if (typeof str === 'string') {
    //     return str.replace(/ä/g, 'ae')
    //               .replace(/ü/g, 'ue')
    //               .replace(/ö/g, 'oe')
    //               .replace(/\//g, '-')
    //               .replace(/{/g, '(') // Replace with opening parenthesis (or any other suitable character)
    //               .replace(/}/g, ')') // Replace with closing parenthesis (or any other suitable character)
    //               .replace(/"/g, ''); // Replace with closing parenthesis (or any other suitable character)
    //   } else {
    //     return str; // Return the value as is if it's not a string or an array
    //   }
    // },

    async loadFromSupabase() {
      const { data: storedScorecards, error } = await supabase
        .from('scorecards')
        .select('contents, name')
        .eq('id', this.$route.params.id);
      if (error) {
        console.error('Error loading scorecards:', error);
        return;
      }
      this.scorecards = storedScorecards[0].contents;
      this.name = storedScorecards[0].name;
    },

    //  chapters
    addChapter() {
      this.addingChapter = true;
      const newChapter = { name: '', topics: [], editing: true };
      this.scorecards[this.selectedScorecardIndex].chapters.push(newChapter);
      this.selectedChapterIndex = this.scorecards[this.selectedScorecardIndex].chapters.length - 1;
      this.$nextTick(() => {
        this.$refs[`chapterInputField${this.selectedChapterIndex}`][0].focus();
      });
      this.saveToSupabase();
    },

    selectChapter(index) {
      this.selectedChapterIndex = index;
    },

    editChapterName(scorecardIndex, chapterIndex) {
      if (this.scorecards[scorecardIndex] && this.scorecards[scorecardIndex].chapters[chapterIndex]) {
        this.scorecards[scorecardIndex].chapters[chapterIndex].editing = true;
        this.$nextTick(() => {
          this.$refs[`chapterInputField${chapterIndex}`][0].style.width = '80%';
          this.$refs[`chapterInputField${chapterIndex}`][0].focus();
        });
      } else {
        console.error(`Chapter at index ${chapterIndex} in scorecard at index ${scorecardIndex} is undefined`);
      }
    },
    saveChapterName(scorecardIndex, chapterIndex) {
      if (this.scorecards[scorecardIndex]) {
        this.scorecards[scorecardIndex].chapters[chapterIndex].editing = false;
        this.saveToSupabase();
      } else {
        console.error(`Scorecard at index ${scorecardIndex} is undefined`);
      }
    },
    deleteChapter(scorecardIndex, chapterIndex) {
      if (this.scorecards[scorecardIndex]) {
        this.scorecards[scorecardIndex].chapters.splice(chapterIndex, 1);
        if (this.scorecards[scorecardIndex].chapters.length <= chapterIndex) {
          this.selectedChapterIndex = null;
        }
        this.saveToSupabase();
      } else {
        console.error(`Scorecard at index ${scorecardIndex} is undefined`);
      }
    },
    selectTopic(chapterIndex, topicIndex) {
      try {
        if (this.selectedChapterIndex !== chapterIndex || this.selectedTopicIndex !== topicIndex) {
          this.selectedChapterIndex = chapterIndex;
          this.selectedTopicIndex = topicIndex;
        }
      } catch (e) {
        this.handleError(e.message);
      }
    },
    // If necessary, additional logic can be added here to handle changes
    // For now, this method ensures that the follow-up changes don't
    // interfere with the current selection state of topics
    handleFollowUpChange(scorecardIndex, chapterIndex, topicIndex) {
      // Save the topic after changes
      this.saveTopic(scorecardIndex, chapterIndex, topicIndex);
      // Keep the details container open
      this.showDetailsContainer = true;
      // Re-select the topic that was just edited
      this.selectedTopic = this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex];
    },

    deleteTopic(scorecardIndex, chapterIndex, topicIndex) {
      if (this.scorecards[scorecardIndex] && this.scorecards[scorecardIndex].chapters[chapterIndex] && this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex]) {
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics.splice(topicIndex, 1);
        if (this.selectedTopicIndex >= this.scorecards[scorecardIndex].chapters[chapterIndex].topics.length) {
          this.selectedTopicIndex = null;
        }
        this.saveToSupabase();
      } else {
        console.error(`Scorecard at index ${scorecardIndex} is undefined`);
      }
    },

    editTopicName(scorecardIndex, chapterIndex, topicIndex) {
      if (this.scorecards[scorecardIndex] &&
        this.scorecards[scorecardIndex].chapters[chapterIndex] &&
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex]) {
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex].editing = true;
        this.selectedTopicIndex = topicIndex;
      } else {
        console.error(`Topic at index ${topicIndex} in chapter at index ${chapterIndex} in scorecard at index ${scorecardIndex} is undefined`);
      }
    },

    saveTopic(scorecardIndex, chapterIndex, topicIndex) {
      // Check if the scorecard, chapter, and topic at the given indices are defined
      if (this.scorecards[scorecardIndex] &&
        this.scorecards[scorecardIndex].chapters &&
        this.scorecards[scorecardIndex].chapters[chapterIndex] &&
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics &&
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex]) {
        // Set editing to false only if the topic is defined
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex].editing = false;
        this.saveToSupabase();
      } else {
        // Log an error if the topic is not defined
        console.error(`Cannot save topic. Topic at index ${topicIndex} in chapter at index ${chapterIndex} in scorecard at index ${scorecardIndex} is undefined`);
      }
    },

    addTopic(scorecardIndex, chapterIndex) {
      // Generate a unique ID for the new topic
      const newTopicId = Date.now();
      const newTopic = {
        id: newTopicId,
        name: '',
        followUp: [
          this.$t('scorecardEditNew.followUp.topic_raised'),
          this.$t('scorecardEditNew.followUp.offer'),
          this.$t('scorecardEditNew.followUp.follow_up_appointment'),
          this.$t('scorecardEditNew.followUp.existing_contract'),
          this.$t('scorecardEditNew.followUp.no_interest')
        ],
        editing: true
      };
      this.scorecards[scorecardIndex].chapters[chapterIndex].topics.push(newTopic);
      this.selectedTopicIndex = this.scorecards[scorecardIndex].chapters[chapterIndex].topics.length - 1; // Select the new topic
      this.$nextTick(() => {
        if (this.$refs[`topicInputField${this.selectedTopicIndex}`]) {
          this.$refs[`topicInputField${this.selectedTopicIndex}`][0].focus();
        }
      });
      this.saveToSupabase();
    },

    applyToTopic(topicDetails, scorecardIndex, chapterIndex, topicIndex) {
      if (this.scorecards[scorecardIndex] &&
        this.scorecards[scorecardIndex].chapters[chapterIndex] &&
        this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex]) {
        Object.assign(this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex], topicDetails);
        this.saveTopic(scorecardIndex, chapterIndex, topicIndex);
      } else {
        if (!this.scorecards[scorecardIndex]) {
          console.error(`Scorecard at index ${scorecardIndex} is undefined`);
        } else if (!this.scorecards[scorecardIndex].chapters[chapterIndex]) {
          console.error(`Chapter at index ${chapterIndex} in scorecard at index ${scorecardIndex} is undefined`);
        } else if (!this.scorecards[scorecardIndex].chapters[chapterIndex].topics[topicIndex]) {
          console.error(`Topic at index ${topicIndex} in chapter at index ${chapterIndex} in scorecard at index ${scorecardIndex} is undefined`);
        }
      }
    },
    handleError(errorMessage) {
      console.error(errorMessage);
      this.errorMessage = errorMessage;
      this.showErrorSnackbar = true;
    },

    async saveToSupabase() {
      const sanitizedScorecards = JSON.parse(JSON.stringify(this.scorecards));
      const newName = this.name;
      const { error } = await supabase
        .from('scorecards')
        .upsert({ id: this.$route.params.id, contents: sanitizedScorecards, name: newName });
      if (error) {
        console.error('Error saving to Supabase:', error);
      } else {
        this.name = newName;
        this.scorecards = sanitizedScorecards;
      }
    },
  }
}
</script>
<style scoped>
.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main-container {
  width: 100%;
  background-color: #FAFBFF;
}

.chapter {
  padding: 10px 0;
  transition: border 0.3s ease;
  border-radius: 6px;
  border: 1px solid #A1A9B8;
  margin: 5% 5% 10px 5%;
  /* top right bottom left */
}

.chapter:not(.chapter-editing):hover {
  border: 1px solid #5932EA;
  z-index: 0;
  /* Ensure the hover effect is behind the trash can */
}

.chapter-editing {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 90%;
  padding: 5%;
}

.chapter-input {
  width: 90%;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 1.5em;
  z-index: 2;
}

.trash-icon {
  cursor: pointer;
  margin-right: 10px;
}

.cog-icon {
  margin-right: 10px;
}

.trash-button {
  width: 10%;
  /* Adjusted to fit 10% of the chapter card */

  z-index: 5;
  /* Bring to front */
  cursor: pointer;
  /* Change cursor to pointer on hover */
}

.topic-content {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding-left: 10px;
  align-items: center;
  width: calc(100% - 20px);
  margin-left: 20px;
}

.topic-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.topic-card {
  color: #A1A9B8;
  padding: 10px;
  text-align: left;
  margin: 10px 10px 10px 10px;
  /* top right bottom left */
  border-radius: 6px;
  width: 90%;

  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    /* Shadow 1 */
    0 0 0 1px rgba(134, 143, 160, 0.16);
  /* Shadow 2 */
}

.topic-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-align: left;
  padding: 0;
  box-sizing: border-box;
}

.active-topic-card {
  border: 1px solid #5932EA;
  color: #5932EA;
  padding: 10px;
  margin: 10px 10px 10px 10px;
  /* top right bottom left */
  border-radius: 6px;
  width: 90%;
}

.chapters-container {
  width: 95%;
  padding: 5px;
  margin: 10px 10px 10px 10px;
  /* top right bottom left */
}

.details-container {
  width: 70%;
  max-width: 70%;
  padding: 10px;
  margin: 5%;
  box-sizing: border-box;
}
</style>