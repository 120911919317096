<template>
  <div class="view-container">
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <h1>{{ $t("logout.title") }}</h1>
    <div class="buttonContainer">
      <v-btn @click="logout" class="me-2 text-none" color="#5932ea" elevation="0">
        {{ $t("logout.button") }}
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { supabase } from '../supabase';
import { ref } from 'vue';

let showErrorSnackbar = ref(false);
let errorMessage = ref("");

async function logout() {
  const { error } = await supabase.auth.signOut();

  if (error) {
    showErrorSnackbar.value = true;
    errorMessage.value = error.message;
  }
  else {
    window.location.href = 'https://www.usetoday.io';
  }
}
</script>



<style scoped>
/* Component styles go here */
</style>