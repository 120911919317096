<template>
	<v-row justify="center">
		<v-col class="d-flex align-center justify-center">
			<v-snackbar v-model="showErrorSnackbar" color="error">
				{{ errorMessage }}
			</v-snackbar>
			<div>
				<div class="spring-animation">
					<svg width="50" height="50" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M11.589 111.156L64.896 63.96L111.303 11.667L129.024 28.935L76.359 75.003L29.082 128.877L11.589 111.156ZM111.078 128.313L64.332 75.003L11.361 29.16L29.307 11.667L76.02 63.732L128.799 111.156L111.078 128.313ZM57.996 140.544L62.139 69.672L57.996 0H82.695L77.985 69.672L82.695 140.544H57.996ZM0 57.768L70.002 61.542L140.769 57.849V82.548L70.158 77.535L0 82.548V57.768Z"
							fill="#5932EA" />
					</svg>
				</div>
				<h2 style="padding: 20px;">{{ $t("login.title") }}</h2>
				<v-card class="view-card" rounded="xl">
					<form @submit.prevent="submit" class="form-content" autocomplete="on">
						<v-text-field v-model="email" label="E-Mail" required name="email" type="email"
							autocomplete="username email" density="compact" variant="outlined"
							class="input-field form-item" base-color="csecondary" color="primary"></v-text-field>

						<v-text-field v-model="password" label="Passwort" id="password" name="password" type="password"
							autocomplete="current-password" variant="outlined" density="compact" max-width="200px"
							required class="input-field form-item" base-color="csecondary"
							color="primary"></v-text-field>

						<v-btn @click="login" class="me-2 text-none btn-spacing" color="#5932ea" rounded="lg">
							{{ $t("login.button") }}
						</v-btn>
						<v-btn variant="tonal" @click="resetpassword" class="me-2 text-none btn-spacing" color="#5932ea"
							rounded="lg">
							{{ $t("login.resetPassword") }}
						</v-btn>
						<!-- <v-row justify="center" style="margin-top: 20px">
							<v-col>
								{{ $t("login.noAccount") }} <router-link to="/register">{{ $t("login.register")
									}}</router-link>
							</v-col>
						</v-row> -->
						<v-row>
							<v-col>
								{{ $t("login.privacy") }} <a href="https://www.usetoday.io/privacy" target="_blank">{{
									$t("login.privacyLink") }}</a>.
							</v-col>
						</v-row>
					</form>
				</v-card>
			</div>
		</v-col>
	</v-row>
</template>

<script setup>
import { ref } from 'vue';
import { supabase } from '../supabase';
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'

const { t } = useI18n();
let email = ref('');
let password = ref('');
const router = useRouter();
let showErrorSnackbar = ref(false);
let errorMessage = ref("");

async function login() {
	try {
		const { error } = await supabase.auth.signInWithPassword({
			email: email.value,
			password: password.value
		})
		if (error) {
			errorMessage.value = t('login.errors.invalid_credentials');
			showErrorSnackbar.value = true;
		} else {
			router.push("/:tenant/");
		}
	} catch (error) {
		errorMessage.value = t('login.errors.server_error');
		showErrorSnackbar.value = true;
	}
}

async function resetpassword() {
	router.push("/password-reset");
}

const { handleSubmit, errors } = useForm({
	validationSchema: {
		email(value) {
			if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true
			return t('login.errors.invalid_email');
		},
	},
})

const submit = handleSubmit(() => {
	if (errors.email) {
		errorMessage.value = errors.email;
		showErrorSnackbar.value = true;
	}
})
</script>

<style scoped>
.view-card {
	width: 400px;
	max-width: 90%;
	margin: 0 auto;
	padding: 20px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-content {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.form-item {
	margin-bottom: 10px;
}

.input-field,
.btn-spacing {
	width: calc(100% - 40px);
	margin: 10px;
}

h2 {
	padding: 20px;
}

v-text-field {
	width: 100%;
}

body,
html {
	height: 100%;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
