<template>
	<v-row justify="center">
		<v-col class="d-flex align-center justify-center">
			<div class="card-snackbar-wrapper">
				<v-snackbar v-model="showSnackbar" color="success">
					{{ $t("register.emailSent") }} {{ email }}
				</v-snackbar>
				<v-snackbar v-model="showErrorSnackbar" color="error">
					{{ errorMessage }}
				</v-snackbar>
				<v-card class="view-card" rounded="xl">
					<form @submit.prevent="createAccount" class="form-content">
						<h2>{{ $t("register.title") }}</h2>
						<p style="margin:15px">{{ $t("register.description") }}</p>
						<v-text-field v-model="email" label="E-Mail" required density="compact" variant="solo"
							class="input-field form-item"></v-text-field>

						<v-text-field v-model="password" label="Password" id="password" type="password" variant="solo"
							density="compact" max-width="200px" required class="input-field form-item"></v-text-field>

						<v-btn @click="createAccount" class="me-2 text-none btn-spacing" color="#5932ea" width="200"
							rounded="lg">
							{{ $t("register.button") }}
						</v-btn>
						<v-row justify="center" style="margin:15px">
							<v-col>
								<p>{{ $t("register.alreadyRegistered") }} <router-link to="/login">{{
									$t("register.login") }}</router-link></p>
								<br>
								<p>{{ $t("register.privacy") }} <a href="https://www.usetoday.io/privacy"
										target="_blank">{{ $t("register.privacyLink") }}</a>.</p>
							</v-col>
						</v-row>
					</form>
				</v-card>
			</div>
		</v-col>
	</v-row>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { supabase } from "../supabase"
import { useRouter, useRoute } from "vue-router";

let email = ref("");
let password = ref("");
let tenant = ref("");
const router = useRouter();
const route = useRoute();
let showSnackbar = ref(false);
let showErrorSnackbar = ref(false);
let errorMessage = ref("");

onMounted(async () => {
	tenant.value = route.params.tenant; 
});

async function createAccount() {
	const { error } = await supabase.auth.signUp({
		email: email.value,
		password: password.value,
	})
	if (error) {
		errorMessage.value = error.message;
		showErrorSnackbar.value = true;
	} else {
		showSnackbar.value = true;
		router.push({ name: 'onboarding-use-case', query: { email: email.value } });
	}
}
</script>

<style scoped>
.btn-spacing {
	margin: 10px;
	/* Adjust this value as needed */
}

.card-snackbar-wrapper {
	width: 400px;
	max-width: 90%;
	margin: 0 auto;
}

.view-card {
	width: 100%;
	padding: 20px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.form-item {
	margin-bottom: 35px;
}

.input-field {
	width: calc(100% - 40px);
	box-shadow: 0px 0px 0px 1px #868FA0 16%;
}

h2 {
	padding: 20px;
}

v-text-field {
	width: 100%;
}

.snackbar {
	width: 100%;
	margin-top: 20px;
}

body,
html {
	height: 100%;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>