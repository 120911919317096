<!-- UI to create org in supabase and associate current user with it. 
  Part of the onboarding and redirect (router) if no tenant present. -->
  <template>
    <v-row justify="center">
      <v-col class="d-flex align-center justify-center">
        <v-snackbar v-model="showErrorSnackbar" color="error">
          {{ errorMessage }}
        </v-snackbar>
        <v-card class="view-card" rounded="xl">
          <form @submit.prevent="createOrganisation" class="form-content">
            <h2 style="padding: 20px;">{{ $t('create.createOrg') }}</h2>
            <p class="subheadline">{{ $t('create.subheadline') }}</p>
            <v-row>
              <v-col cols="6">
                <v-text-field 
                  v-model="firstName" 
                  :label="$t('create.firstName')" 
                  required 
                  density="compact" 
                  variant="solo"
                  class="input-field form-item"
                  style="min-width: 200px"
                ></v-text-field>
                <v-text-field 
                  v-model="lastName" 
                  :label="$t('create.lastName')" 
                  required 
                  density="compact" 
                  variant="solo"
                  class="input-field form-item"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field 
                  v-model="organisation" 
                  :label="$t('create.firmenname')" 
                  id="organisation" 
                  variant="solo"
                  density="compact" 
                  required 
                  class="input-field form-item"
                  style="min-width: 200px"
                ></v-text-field>
                <v-file-input 
                  v-model="logo" 
                  :label="$t('create.logo')" 
                  accept="image/*" 
                  density="compact" 
                  variant="solo"
                  class="input-field form-item" 
                  prepend-icon="mdi-camera"
                  @change="handleLogoChange"
                ></v-file-input>
               
              </v-col>
            </v-row>
            <v-btn 
              @click="createOrganisation" 
              class="me-2 text-none btn-spacing" 
              color="#5932ea" 
              width="200"
              rounded="lg"
            >
              {{ $t('create.create') }}
            </v-btn>
          </form>
        </v-card>
      </v-col>
    </v-row>
  </template>
  
  <script setup>
  import { ref } from "vue";
  import { supabase } from "../supabase"
  import { useUser } from "../useUser";
  import { useRouter } from 'vue-router';
  
  const router = useRouter();
  const { user } = useUser();
  
  let firstName = ref("");
  let lastName = ref("");
  let organisation = ref("");
  let logo = ref(null);
  let processedLogoPreview = ref(null);
  
  let showErrorSnackbar = ref(false);
  let errorMessage = ref("");
  
  let customerId = ref("");
  
  // create new organisation in "organisations" table
  async function createOrganisation() {
    const currentUser = user.value.id;
    
    // Upload logo if provided
    let base64Logo = null;
    if (logo.value && logo.value[0]) {
      console.log('Processing logo for organization creation');
      const file = logo.value[0];
      base64Logo = await processLogo(file);
      console.log('Base64 logo generated for organization:', base64Logo?.substring(0, 50) + '...');
    } else {
      // Fetch fallback logo from specified organization
      const { data: fallbackOrgData, error: fallbackError } = await supabase
        .from('organisations')
        .select('recall_bot_base64_image')
        .eq('id', 'baee000e-3b9d-4a25-9c03-4e861cd0e3b5')
        .single();
      
      if (fallbackError) {
        console.error('Error fetching fallback logo:', fallbackError);
      } else {
        base64Logo = fallbackOrgData.recall_bot_base64_image;
        console.log('Using fallback logo from organization');
      }
    }

    const { data: orgData, error: orgError } = await supabase
      .from("organisations")
      .upsert([{ 
        name: organisation.value.replace(/\s/g, ''), 
        associated_user_ids: [currentUser],
        recall_bot_base64_image: base64Logo  // Store the base64 directly
      }])
      .select('id');
    if (orgError) {
      console.error(orgError);
      showErrorSnackbar.value = true;
      errorMessage.value = orgError.message;
      return;
    }
    // Assuming the organisation was created successfully and we have the id
    const newOrgId = orgData[0].id;

    // Copy scorecard 147 for the new organization
    const { data: scorecardData, error: scorecardError } = await supabase
      .from('scorecards')
      .select('contents, name')
      .eq('id', 147)
      .single();

    if (scorecardError) {
      console.error('Error fetching scorecard:', scorecardError);
      showErrorSnackbar.value = true;
      errorMessage.value = scorecardError.message;
      return;
    }

    // Create new scorecard with updated organization
    const scorecardToInsert = {
      ...scorecardData,
      organisation: newOrgId
    };

    const { data: newScorecardData, error: newScorecardError } = await supabase
      .from('scorecards')
      .insert([scorecardToInsert])
      .select();

    if (newScorecardError) {
      console.error('Error creating new scorecard:', newScorecardError);
      showErrorSnackbar.value = true;
      errorMessage.value = newScorecardError.message;
      return;
    }

    // Create new conversation type directly
    const convTypeToInsert = {
      name: "TODAY - Beispielgespräch",
      user_id: currentUser,
      associated_organisation_id: newOrgId,
      associated_scorecard: newScorecardData[0].id
    };

    const { error: newConvTypeError } = await supabase
      .from('conversationtypes')
      .insert([convTypeToInsert]);

    if (newConvTypeError) {
      console.error('Error creating new conversation type:', newConvTypeError);
      showErrorSnackbar.value = true;
      errorMessage.value = newConvTypeError.message;
      return;
    }
  
    // create new entry in "advisors" table
    const { error: advisorError } = await supabase
      .from("advisors")
      .insert([{ 
        first_name: firstName.value, 
        last_name: lastName.value,
        user_id: currentUser, 
        organisation: newOrgId 
      }])
      .select('id');
    if (advisorError) {
      console.error(advisorError);
      showErrorSnackbar.value = true;
      errorMessage.value = advisorError.message;
    }
    // create new entry in "customers" table  
    const { data: customerData, error: customerError } = await supabase
      .from("customers")
      .insert([{ first_name: "Demo", last_name: "Kunde", organisation: newOrgId }])
      .select('id');
    if (customerError) {
      console.error(customerError);
      showErrorSnackbar.value = true;
      errorMessage.value = customerError.message;
      return;
    }
    customerId.value = customerData[0].id;
  
  
    // duplicate call #230
    const { data: callData, error: callError } = await supabase
      .from('calls')
      .select('file_reference, transcript_file_name, insights, conversation_type, scorecard_id, coaching_available')
      .eq('id', 230)
      .single();
  
    if (callError) {
      console.error(callError);
      showErrorSnackbar.value = true;
      errorMessage.value = callError.message;
      return;
    }
  
    if (!callData) {
      console.error('No data returned for call with id 230');
      showErrorSnackbar.value = true;
      errorMessage.value = 'No data returned for call with id 230';
      return;
    }
  
    // Load the transcript file from Supabase storage using the file_reference from the calls table
    const transcriptFileName = callData.transcript_file_name;
    const fileNameWithoutPath = Array.isArray(transcriptFileName) && transcriptFileName.length > 0 ? transcriptFileName[0].split('/').pop() : '';
    // const signedTranscriptFileUrl = fileNameWithoutPath ? `https://tswijmzbixtawidabpuq.supabase.co/storage/v1/object/sign/coveredbucket/${fileNameWithoutPath}` : '';
    let transcriptFile = null;
  
    // Fetch the transcript file from the storage
    transcriptFile = await supabase
      .storage
      .from('coveredbucket')
      .download(fileNameWithoutPath);
    if (transcriptFile.error) {
      throw transcriptFile.error;
    }
  
    // Parse the JSON content of the transcript file
    let transcriptContent;
    try {
      transcriptContent = JSON.parse(await transcriptFile.data.text());
    } catch (jsonError) {
      console.error('Failed to parse transcript JSON:', jsonError);
      showErrorSnackbar.value = true;
      errorMessage.value = 'Failed to parse transcript JSON';
      return;
    }
    // Replace the keys in the transcript JSON content
    if (!Array.isArray(transcriptContent.transcription)) {
      console.error('transcriptContent.transcription is not an array:', transcriptContent.transcription);
      showErrorSnackbar.value = true;
      errorMessage.value = 'Invalid transcript content format';
      return;
    }
    // here is the problem: write user id instead of adviosor.id
    transcriptContent.transcription = transcriptContent.transcription.map(entry => {
      if (entry.role_id === "082d87a4-4988-4e9a-8a8b-67908e4bd1dc") {
        entry.role_id = currentUser;
      } else if (entry.role_id === "13") {
        entry.role_id = customerId.value;
      }
      return entry;
    });
    const fileBaseName = fileNameWithoutPath.replace('.json', '');
    const transcriptwithupdatedkeys = `${fileBaseName}-${newOrgId}.json`;
    const newtranscripturl = transcriptwithupdatedkeys ? `https://tswijmzbixtawidabpuq.supabase.co/storage/v1/object/sign/coveredbucket/${transcriptwithupdatedkeys}` : '';
  
    // Convert the updated transcript content back to a Blob for upload
    const updatedTranscriptBlob = new Blob([JSON.stringify(transcriptContent)], { type: 'application/json' });
  
    // Store the updated transcript file in Supabase under the same filename
    const { error: updateTranscriptError } = await supabase
      .storage
      .from('coveredbucket')
      .upload(transcriptwithupdatedkeys, updatedTranscriptBlob, {
        cacheControl: '3600',
        upsert: true
      });
  
  
    if (updateTranscriptError) {
      console.error(updateTranscriptError);
      showErrorSnackbar.value = true;
      errorMessage.value = updateTranscriptError.message;
      return;
    }
  
    // eslint-disable-next-line
    const { id, ...callDataWithoutId } = callData;
    const callToInsert = { ...callDataWithoutId, user_id: currentUser, organisation: newOrgId, customer_id: customerId.value, transcript_file_name: [newtranscripturl] };
    const { data: insertedCallData, error: insertedCallError } = await supabase
      .from('calls')
      .insert([callToInsert])
      .select()
  
    if (insertedCallError) {
      console.error(insertedCallError);
      showErrorSnackbar.value = true;
      errorMessage.value = insertedCallError.message;
      return;
    }
  
    if (!insertedCallData || insertedCallData.length === 0) {
      console.error('No data returned after inserting call');
      showErrorSnackbar.value = true;
      errorMessage.value = 'No data returned after inserting call';
      return;
    }
  
    const newCallUuid = insertedCallData[0].id;
  
    const { error: updateOrgError } = await supabase
      .from('organisations')
      .update({ demo_call: newCallUuid })
      .eq('id', newOrgId);
    if (updateOrgError) {
      console.error(updateOrgError);
      showErrorSnackbar.value = true;
      errorMessage.value = updateOrgError.message;
      return;
    }
  
    await fetchTenantAndRedirect(currentUser);
  }
  
  async function fetchTenantAndRedirect(currentUser) {
    const { data: tenantData, error: tenantError } = await supabase
      .from('organisations')
      .select('name')
      .contains('associated_user_ids', [currentUser]);
    if (tenantError) {
      console.error('Error fetching tenant:', tenantError);
      showErrorSnackbar.value = true;
      errorMessage.value = tenantError.message;
    }
    // Redirect to /:tenant/
    if (tenantData && tenantData.length > 0) {
      const tenant = tenantData[0].name;
      router.push(`/${tenant}/`);
    }
  }
  
  // Add this function before createOrganisation
  async function processLogo(input) {
    console.log('Processing logo with input type:', typeof input);
    
    try {
      const formData = new FormData();
      formData.append('file', input);
      
      console.log('Removing background from logo...');
      const response = await fetch('/api/removebg', {
        method: 'POST',
        body: formData
      });
      
      if (!response.ok) {
        throw new Error(`Background removal failed: ${response.statusText}`);
      }
      
      const result = await response.json();
      if (!result.success) {
        throw new Error(result.error || 'Background removal failed');
      }

      return new Promise((resolve) => {
        // Create canvas and process the no-background image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 640;
        canvas.height = 360;
        console.log('Canvas created with dimensions:', canvas.width, 'x', canvas.height);

        // Draw background with specific hex color #212429
        ctx.fillStyle = '#212429';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Load and draw logo
        const logo = new Image();
        logo.crossOrigin = "anonymous";
        
        logo.onload = () => {
          console.log('Logo loaded with dimensions:', logo.width, 'x', logo.height);
          // Calculate dimensions (27% max width, 10% max height)
          const maxWidth = canvas.width * 0.27;
          const maxHeight = canvas.height * 0.10;
          let logoWidth = logo.width;
          let logoHeight = logo.height;

          // Scale logo to fit constraints while maintaining aspect ratio
          if (logoWidth > maxWidth) {
            const scale = maxWidth / logoWidth;
            logoWidth *= scale;
            logoHeight *= scale;
          }
          if (logoHeight > maxHeight) {
            const scale = maxHeight / logoHeight;
            logoWidth *= scale;
            logoHeight *= scale;
          }

          console.log('Logo scaled to:', logoWidth, 'x', logoHeight);

          // Center the logo
          const x = (canvas.width - logoWidth) / 2;
          const y = (canvas.height - logoHeight) / 2;

          // Draw logo
          ctx.drawImage(logo, x, y, logoWidth, logoHeight);
          // const base64WithPrefix = canvas.toDataURL('image/png');
          const base64WithPrefix = canvas.toDataURL('image/jpeg', 0.8); // 0.8 = quality
          // Strip the data URL prefix
          // const base64 = base64WithPrefix.replace(/^data:image\/png;base64,/, '');
          const base64 = base64WithPrefix.replace(/^data:image\/jpeg;base64,/, '');
          console.log('Base64 generated (without prefix), length:', base64.length);
          resolve(base64);
        };

        logo.onerror = (e) => {
          console.error('Error loading logo:', e);
          resolve(null);
        };

        // Create a blob URL from the background-removed image
        const binaryString = atob(result.image);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'image/png' });
        logo.src = URL.createObjectURL(blob);
      });

    } catch (error) {
      console.error('Error processing logo:', error);
      return null;
    }
  }
  
  async function handleLogoChange(files) {
    console.log('Logo change triggered with files:', files);
    if (!files || !files[0]) {
      console.log('No files selected');
      processedLogoPreview.value = null;
      return;
    }

    const file = files[0];
    console.log('Processing file:', file.name, 'type:', file.type);
    const base64 = await processLogo(file);
    console.log('Preview base64 generated:', base64?.substring(0, 50) + '...');
    processedLogoPreview.value = `data:image/png;base64,${base64}`;
  }
  </script>
  
  <style scoped>
  .btn-spacing {
    margin: 10px;
  }
  
  .view-card {
    width: 60%;
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .form-item {
    margin-bottom: 20px;
    min-width: 200px;
  }
  
  .input-field {
    width: calc(100% - 40px);
    box-shadow: 0px 0px 0px 1px #868FA0 16%;
  }
  
  h2 {
    padding: 20px;
  }
  
  v-text-field {
    width: 100%;
  }
  
  body,
  html {
    height: 100%;
  }
  
  body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .section-title {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 15px;
    align-self: flex-start;
    width: 100%;
    padding-left: 20px;
  }
  
  .preview-container {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    display: block;
  }


  .subheadline {
    color: #9197B3;
    font-size: 1rem;
    text-align: center;
    margin: 0;
    padding: 0 20px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  </style>