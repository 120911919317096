<template>
  <div class="card-container">
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <v-card style="width: 100%;" class="card rounded-xl">
      <!-- <v-card style="width: 80%; margin: 0 auto; padding: 25px; box-sizing: border-box; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 15px;" class="card rounded-xl"> -->
      <v-breadcrumbs style="margin-left: 15px;">
        <v-breadcrumbs-item>{{ $t('customers.title') }}</v-breadcrumbs-item>
      </v-breadcrumbs>
      <div style="margin-bottom: 15px; padding: 25px; display: flex; align-items: flex-start;">

        <h1 style="text-align: left">{{ $t('customers.title') }}</h1>
        <v-table :hover="true" fixed-header height="700px">
          <thead>
            <tr>
              <th class="text-center">{{ $t('customers.id') }}</th>
              <th class="text-center">{{ $t('customers.firstName') }}</th>
              <th class="text-center">{{ $t('customers.lastName') }}</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers" :key="customer.id">
              <td><router-link :to="`/:tenant/customers/${customer.id}`">{{ customer.id }}</router-link></td>
              <td><router-link :to="`/:tenant/customers/${customer.id}`">{{ customer.first_name }}</router-link></td>
              <td><router-link :to="`/:tenant/customers/${customer.id}`">{{ customer.last_name }}</router-link></td>
              <td class="text-center">
                <v-icon @click="confirmDelete(customer)" class="delete-icon">
                  mdi-delete-outline
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card>
    <v-dialog v-model="showDeleteDialog" style="max-width:800px">
      <v-card>
        <v-card-title class="text-h5">{{ $t('customers.deleteCustomer') }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant=tonal color="csecondary" text @click="showDeleteDialog = false">{{ $t('customers.cancel')
            }}</v-btn>
          <v-btn variant=tonal color="error" text @click="deleteCustomer">{{ $t('customers.delete') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { supabase, userState } from "../supabase";
import { useRoute } from 'vue-router';

export default {
  setup() {
    const id = ref(null);
    const customers = ref([]);
    const route = useRoute();
    const showDeleteDialog = ref(false);
    const customerToDelete = ref(null);
    const showErrorSnackbar = ref(false);
    const errorMessage = ref("");

    onMounted(async () => {
      id.value = route.params.id;
      const user = userState.currentUser;
      if (!user) throw new Error('User not found');

      // This is how you filter an array column to match a specific uuid
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) {
        console.error('Organisations query error:', orgError); // Log any query errors
        throw orgError;
      }
      const organisationIds = organisations.map(org => org.id);

      try {
        const { data: fetchedCustomers, error: customerError } = await supabase
          .from("customers")
          .select("id, first_name, last_name")
          .in('organisation', organisationIds) // new for multi-tenancy
        if (customerError) throw customerError;
        customers.value = fetchedCustomers;
      } catch (error) {
        console.error(error);
      }
    });
    const confirmDelete = (customer) => {
      customerToDelete.value = customer;
      showDeleteDialog.value = true;
    };

    const deleteCustomer = async () => {
      if (!customerToDelete.value) return;
      const { error } = await supabase
        .from('customers')
        .delete()
        .eq('id', customerToDelete.value.id);

      if (error) {
        console.error(error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      } else {
        customers.value = customers.value.filter((customer) => customer.id !== customerToDelete.value.id);
        showDeleteDialog.value = false;
      }
    };
    return {
      customers,
      confirmDelete,
      showDeleteDialog,
      deleteCustomer,
    };
  },
}
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.delete-icon {
  color: #9197B3;
}

.delete-icon:hover {
  color: #EC5B84;
}
</style>