<template>
  <div class="advisors-add-container">
    <v-card style="width: 100%;" class="card rounded-xl">
      <div class="card-snackbar-wrapper">
        <v-snackbar v-model="showSnackbar" color="success">
          {{ $t('advisorAdd.advisorAdded') }}
        </v-snackbar>
        <v-snackbar v-model="showErrorSnackbar" color="error">
          {{ errorMessage }}
        </v-snackbar>
      </div>
      <div
        style="margin-bottom: 15px; margin-left: 20px; padding: 25px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <h1 style="text-align: left; margin-bottom: 15px;">{{ $t('advisorAdd.addAdvisor') }}</h1>
        <div style="display: flex; align-items: flex-start;">
          <v-form ref="form" v-model="valid" style="max-width: 350px;">
            <v-text-field v-model="advisor.first_name" :label="$t('advisorAdd.firstName')" required
              variant="solo"></v-text-field>
            <v-text-field v-model="advisor.last_name" :label="$t('advisorAdd.lastName')" required
              variant="solo"></v-text-field>
            <v-text-field v-model="email" :label="$t('advisorAdd.email')" required variant="solo"></v-text-field>
            <v-checkbox v-model="advisor.is_leader" :label="$t('advisorAdd.isLeader')" variant="solo"></v-checkbox>
            <v-select v-if="!advisor.is_leader" v-model="leader" :items="advisors" item-title="name" item-value="id"
              :label="advisor.first_name ? $t('advisorAdd.leaderOf', { name: advisor.first_name }) : $t('advisorAdd.leader')"
              :multiple="false" chips variant="solo"></v-select>

            <v-select v-if="advisor.is_leader" v-model="team" :items="advisors" item-title="name" item-value="id"
              :label="advisor.first_name ? $t('advisorAdd.teamOf', { name: advisor.first_name }) : $t('advisorAdd.team')"
              multiple chips variant="solo"></v-select>
            <v-btn :disabled="!valid" @click="saveUser" class="primary-btn">
              {{ $t('advisorAdd.save') }}
            </v-btn>
          </v-form>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { supabase, userState } from "../supabase";
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';



export default {
  setup() {
    const { t } = useI18n();
    const advisors = ref([]);
    const advisor = ref({
      first_name: '',
      last_name: '',
      is_leader: false,
    });
    const valid = ref(false);
    const leader = ref([]);
    const team = ref([]);
    const organisationIds = ref([]);
    let email = ref("");
    let showSnackbar = ref(false);
    let showErrorSnackbar = ref(false);
    let errorMessage = ref("");
    const router = useRouter();

    onMounted(async () => {
      const user = userState.currentUser;
      if (!user) {
        showErrorSnackbar.value = true;
        errorMessage.value = t('advisorAdd.errors.user_not_found');
        throw new Error('User not found');
      }
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) {
        showErrorSnackbar.value = true;
        errorMessage.value = t('advisorAdd.errors.org_error');
      }
      organisationIds.value = organisations.map(org => org.id);

      const { data: advisorsData, error: advError } = await supabase
        .from('advisors')
        .select('id, first_name, last_name, organisation')
        .in('organisation', organisationIds.value);
      if (advError) {
        showErrorSnackbar.value = true;
        errorMessage.value = t('advisorAdd.errors.fetch_error');
      }
      advisors.value = Array.isArray(advisorsData) ? advisorsData.map(a => ({
        id: a.id,
        name: `${a.first_name} ${a.last_name}`
      })) : [];
    });

    const saveUser = async () => {
      if (advisor.value.is_leader) {
        advisor.value.direct_reports = team.value.map(t => t.id);
      } else if (leader.value.length > 0) {
        advisor.value.leader = [leader.value[0].id];
      }

      const userData = {
        firstName: advisor.value.first_name,
        lastName: advisor.value.last_name,
        email: email.value,
        organisationIds: organisationIds.value[0],
        leader: leader.value,
        direct_reports: team.value,
      };

      try {
        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/save-user', userData);
        if (response.status === 200) {
          const { error } = await supabase.auth.resetPasswordForEmail(email.value, {
            redirectTo: process.env.VUE_APP_BASE_ROUTE + '/update-password',
          });
          if (error) {
            showErrorSnackbar.value = true;
            errorMessage.value = t('advisorAdd.errors.invitation_error');
          } else {
            showSnackbar.value = true;
          }
          router.back();
        }
      } catch (error) {
        showErrorSnackbar.value = true;
        errorMessage.value = t('advisorAdd.errors.save_error');
      }
    };

    return {
      advisor,
      email,
      organisationIds,
      valid,
      leader,
      team,
      saveUser,
      advisors,
      t,
    };
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.primary-btn {
  background-color: #5932ea;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
</style>