<template>
    <div class="relative max-w-sm rounded-lg overflow-hidden shadow-lg bg-white">
        <div class="relative" style="background: radial-gradient(circle, #7CC3F8, #98D5FF); padding-bottom: 10px;">
            <div class="relative p-6 flex items-center justify-between">
                <div class="logo-tags-container flex items-center justify-between w-full">
                    <div class="logo-container">
                        <img src="https://tswijmzbixtawidabpuq.supabase.co/storage/v1/object/sign/coveredbucket/Logo_Lieblingsmakler.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb3ZlcmVkYnVja2V0L0xvZ29fTGllYmxpbmdzbWFrbGVyLnBuZyIsImlhdCI6MTczMTk1ODAwOSwiZXhwIjoxOTIzNDQzODAwOX0.r6AEmDLwDIG3XGs9Sky2BdG7rd5fVyCJXj9brjt1XNo&t=2024-11-18T19%3A26%3A49.130Z"
                            alt="Logo" class="logo" />
                    </div>
                    <div class="tags-container flex flex-wrap justify-end max-w-[50%] truncate">
                        <v-chip v-for="tag in props.tags" :key="tag" label color="white" text-color="white"
                            class="m-[20px]" style="margin:5px">
                            {{ tag }}
                        </v-chip>
                    </div>
                </div>
            </div>
            <h2 class="text-2xl font-bold text-white mt-12 leading-tight" style="margin-bottom: 10px;">
                {{ props.title }}
            </h2>
        </div>

        <div class="content">
            <div class="author-price">
                <div class="author-info">
                    <img src="https://tswijmzbixtawidabpuq.supabase.co/storage/v1/object/public/Framer%20assets/Robert_Peukert.png?t=2024-11-18T19%3A45%3A33.560Z"
                        class="author-img" :alt="props.authorName" />
                    <div class="author-details">
                        <p class="author-name">{{ props.authorName }}</p>
                        <p class="author-title">{{ props.authorTitle }}</p>
                    </div>
                </div>
                <span class="price">{{ props.price }}</span>
            </div>
            <p class="description">{{ props.description }}</p>

            <!-- Header 1 section -->
            <div v-if="props.header1 && props.text1 && props.price1 && props.ctaText1" class="detail-section">
                <div class="left-align">
                    <h3>{{ props.header1 }}</h3>
                    <ul class="bullet-list" v-html="props.text1"></ul>
                </div>
                <div class="right-align">
                    <p class="price">{{ props.price1 }}</p>
                    <button class="cta-button" @click="subscribetoscorecard">
                        <span>{{ props.ctaText1 }}</span>
                    </button>
                </div>
            </div>

            <!-- Header 2 section -->
            <div v-if="props.header2 && props.text2 && props.price2 && props.ctaText2" class="detail-section">
                <div class="left-align">
                    <h3>{{ props.header2 }}</h3>
                    <ul class="bullet-list" v-html="props.text2"></ul>
                </div>
                <div class="right-align">
                    <p class="price">{{ props.price2 }}</p>
                    <button class="cta-button" @click="$emit('navigateToLink', props.ctaLink2)">
                        <span>{{ props.ctaText2 }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-snackbar-wrapper">
        <v-snackbar v-model="showSnackbar" color="success">
            Scorecard erfolgreich freigeschaltet
        </v-snackbar>
    </div>
</template>


<script setup>
import { defineProps, ref } from 'vue'
// import { useRouter } from 'vue-router'
import { userState } from "../supabase"
import axios from 'axios'


const props = defineProps({
    title: {
        type: String,
        required: true
    },
    price: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: true
    },
    authorName: {
        type: String,
        required: true
    },
    authorTitle: {
        type: String,
        required: true
    },
    tags: {
        type: Array,
        required: true
    },
    link: {
        type: String,
        required: true
    },
    header1: {
        type: String,
        required: false
    },
    text1: {
        type: String,
        required: false
    },
    price1: {
        type: String,
        required: false
    },
    ctaText1: {
        type: String,
        required: false
    },
    header2: {
        type: String,
        required: false
    },
    text2: {
        type: String,
        required: false
    },
    price2: {
        type: String,
        required: false
    },
    ctaText2: {
        type: String,
        required: false
    },
    ctaLink2: {
        type: String,
        required: false
    }
});

// const router = useRouter();
let showSnackbar = ref(false);

const subscribetoscorecard = async () => {
    const user = userState.currentUser;
    if (!user) {
        console.error('User not found');
        return;
    }

    let scorecardId;
    let conversationTypeName;

    // Determine scorecardId and conversationTypeName based on header or other criteria
    if (props.title.includes("Jahresgespräch")) {
        scorecardId = 134;
        conversationTypeName = "Jahresgespräch";
    } else if (props.title.includes("vorsorge")) {
        scorecardId = 143;
        conversationTypeName = "Traumzeitplanung";
    }

    await axios.post(
        process.env.VUE_APP_BACKEND_URL + '/api/subscribe_to_scorecard',
        {
            user_id: user.id,
            scorecard_id: scorecardId,
            conversation_type_name: conversationTypeName
        },
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
    );
    showSnackbar.value = true;
}
</script>



<style scoped>
.bg-gradient-radial {
    background: radial-gradient(circle at top, #009EE0 90%, #009EE0 20%);
}

style scoped>.template-card {
    position: relative;
    max-width: 24rem;
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    border: 1px solid #FAFBFF;
}

.header {
    position: relative;
    height: 12rem;
    background: radial-gradient(circle, #7CC3F8, #98D5FF);
    padding: 1.5rem;
}

.logo-tags-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    flex-shrink: 0;
    margin: 5px;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-grow: 1;
    margin-left: 1rem;
}

.tag {
    background-color: #7cc3f8;
    color: white;
    padding: 0.25rem;
    margin: 0.25rem;
}

.title {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 3rem;
    line-height: 1.25;
    margin-bottom: 2rem;
}

.bullet-list {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    /* Ensuring left alignment */
}

.content {
    padding: 1.5rem;
    background-color: white;
}

.author-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.author-img {
    width: 3.9rem;
    height: 4.02rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.author-info {
    display: flex;
    align-items: left;
}

.author-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.author-name {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: black;
}

.author-title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color: #9197B3;
}

.price {
    color: #000000;
    font-weight: 600;
    margin-left: auto;
}

.description {
    color: #4a4a4a;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    text-align: left;
}

.cta-button {
    width: 100%;
    color: #5932EA;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: color 0.2s;
    margin-top: 1rem;
}

.arrow-icon {
    width: 1rem;
    height: 1rem;
}

.detail-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}


.left-align {
    flex: 1;
    padding-right: 10px;
}

.right-align {
    text-align: right;
    padding-left: 10px;
}

.price {
    margin-bottom: 10px;
}

.cta-button {
    display: inline-flex;
    align-items: center;
    background-color: #5932ea;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}
</style>