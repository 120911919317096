<template>
    <div>
        <div class="accordion-header" @click="toggleCards">
            <h3 style="text-align: left; padding-bottom: 15px">{{ $t('crosssellingTable.success') }}
            </h3>
            <v-icon style="color: #9197b3; margin-left: 15px; padding-bottom: 15px">{{ cardsVisible ? 'mdi-chevron-up' :
                'mdi-chevron-down'
                }}</v-icon>
        </div>
        <v-table v-if="cardsVisible" style="width:50%">
            <thead>
                <tr>
                    <th class="text-left">{{ $t('crosssellingTable.date') }}</th>
                    <th class="text-left">{{ $t('crosssellingTable.advisor') }}</th>
                    <th class="text-left">{{ $t('crosssellingTable.topic') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="call in callsWithCrossSelling" :key="call.id" @click="navigateToTranscript(call.id)">
                    <td>{{ formatDate(call) }}</td>
                    <td>{{ call.advisorName || 'N/A' }}</td>
                    <td>{{ call.crossSellingTopic }}</td>
                </tr>
            </tbody>
        </v-table>
        <div v-if="callsWithCrossSelling.length === 0">
            {{ $t('crosssellingTable.empty') }}
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { supabase } from "../supabase";
import { useUser } from "../useUser";
import { useRoute, useRouter } from "vue-router";



const { user } = useUser();
const userId = ref(user.value ? user.value.id : null);
const callsWithData = ref([]);
const route = useRoute();
const router = useRouter();
const tenant = route.params.tenant;

const cardsVisible = ref(true); // Initially show the table

const toggleCards = () => {
    cardsVisible.value = !cardsVisible.value;
};



const formatDate = (call) => {
    if (call.start) {
        const date = new Date(call.start);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    } else if (call.created_at) {
        const date = new Date(call.created_at);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    } else {
        return 'N/A';
    }
};


const navigateToTranscript = (callId) => {
    console.log("navigatingtotranscript")
    router.push(`/${tenant}/calls/${callId}/transcript-tab`);
};

const parseAndNormalizeTopic = (topic) => {
    const normalizedTopic = { ...topic };
    if (typeof normalizedTopic.topic_id === 'object') {
        Object.assign(normalizedTopic, normalizedTopic.topic_id);
        delete normalizedTopic.topic_id;
    }

    normalizedTopic.topic_score = Number(normalizedTopic.topic_score);
    normalizedTopic.cross_selling_score = Number(normalizedTopic.cross_selling_score);

    return normalizedTopic;
};

onMounted(async () => {
    try {
        const { data: organisations } = await supabase
            .from('organisations')
            .select('id')
            .contains('associated_user_ids', [userId.value])
        let orgId = null;
        if (organisations && organisations.length > 0) {
            orgId = organisations[0].id;
        } else {
            console.error("No organization found for the given userId");
        }
        const { data: calls, error } = await supabase
            .from('calls')
            .select(`
                    id,
                    insights,
                    advisor_id,
                    advisors (
                        first_name,
                        last_name
                    ),
                    start, 
                    created_at 
                `)
            .eq('organisation', orgId)
            .neq('insights', null);
        if (error) {
            console.error('Error fetching call details:', error);
            return;
        }

        // 2. Parse each call and fetch advisor details
        for (const call of calls) {
            if (call.insights && call.insights.coaching_insights) {
                const latestCoachingInsights = call.insights.coaching_insights[call.insights.coaching_insights.length - 1];
                // Check if 'coaching_insights' and 'content' exist
                if (latestCoachingInsights && latestCoachingInsights.coaching_insights && Array.isArray(latestCoachingInsights.coaching_insights.content)) {
                    const normalizedInsights = latestCoachingInsights.coaching_insights.content.map(parseAndNormalizeTopic);
                    const crossSellingItem = normalizedInsights.find(item => item.cross_selling_score === 1);
                    call.crossSellingTopic = crossSellingItem ? crossSellingItem.cross_selling_topic : null;
                    if (call.advisor_id && call.advisors) {
                        call.advisorName = `${call.advisors.first_name} ${call.advisors.last_name}`;
                    }
                } else {
                    // Handle the case where 'coaching_insights' or 'content' is missing
                    call.crossSellingTopic = null; // Or set it to some other default value if needed
                }
            } else {
                // Handle the case where 'coaching_insights' is missing
                call.crossSellingTopic = null;
            }
        }
        callsWithData.value = calls;

    } catch (error) {
        console.error('Error processing insights data:', error);
    }
});

const callsWithCrossSelling = computed(() => {
    return callsWithData.value.filter(call => call.crossSellingTopic !== null);
});

</script>

<style scoped>
.accordion-header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;
    gap: 30px;
    margin-right: 15px;
}
</style>