<template>
  <v-row justify="center">
    <v-col class="d-flex align-center justify-center">
      <div class="card-snackbar-wrapper">
        <v-card class="view-card" rounded="xl">
          <div class="form-content">
            <h2>{{ $t('onboarding.useCase.title') }}</h2>
            <v-radio-group v-model="selectedUseCase" class="form-item" color="#5932ea">
              <v-radio
                :label="$t('onboarding.useCase.personal')"
                value="personal"
                color="#5932ea"
              ></v-radio>
              <v-radio
                :label="$t('onboarding.useCase.team')"
                value="team"
                color="#5932ea"
              ></v-radio>
              <div v-if="selectedUseCase === 'team'" class="text-body-2 mt-4 hint-text">
                {{ $t('onboarding.useCase.subtitle') }}
              </div>
            </v-radio-group>
            <v-btn
              color="#5932ea"
              @click="handleContinue"
              :disabled="!selectedUseCase"
              width="200"
              rounded="lg"
              class="me-2 text-none btn-spacing"
            >
              {{ $t('common.continue') }}
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  name: 'ViewOnboardingUseCase',
  setup() {
    const router = useRouter();
    return { router };
  },
  data: () => ({
    selectedUseCase: null,
  }),
  methods: {
    handleContinue() {
      if (this.selectedUseCase === 'personal') {
        this.router.push('/new-org');
      } else if (this.selectedUseCase === 'team') {
        window.location.href = 'https://cal.com/usetoday/onboarding';
      }
    },
  },
}
</script>

<style scoped>
.btn-spacing {
  margin: 10px;
}

.card-snackbar-wrapper {
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
}

.view-card {
  width: 100%;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-item {
  margin-bottom: 35px;
  width: calc(100% - 40px);
}

h2 {
  padding: 20px;
}

.hint-text {
  color: #9197B3;
  text-align: left;
  width: 100%;
  padding-left: 36px;
}
</style> 