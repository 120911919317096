<template>
  <v-card class="pa-4">
    <v-card-title class="text-h6 font-weight-bold purple white--text pa-2 mb-4">
    </v-card-title>
    <v-card-text class="pa-4">
      <div class="d-flex align-center justify-space-between mb-2">
        <div>
          <h3 style="text-align: left">{{ $t('feedbackCard.feedback') }}</h3>
          <div class="text-caption text-grey">{{ formatDate(date) }}</div>
        </div>
        <div class="rating">
          <div v-for="i in 5" :key="i" class="rating-box"
            :class="{ 'filled': i <= rating, 'hovered': hoveredRating >= i }" @mouseenter="hoveredRating = i"
            @mouseleave="hoveredRating = 0" @click="rating = i">
            <Flame :size="24" fill="white" stroke="none" />
          </div>
        </div>

      </div>
      <v-expand-transition>
        <div v-if="!editing" feedback-items>
          <div v-for="(item, index) in feedbackItems" :key="index" class="feedback-item">
            <strong @click="playAudioAtTime(item.time)" class="clickable-time">{{ item.time }}</strong>
            {{ item.text }}
          </div>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="editing">
          <v-textarea v-model="editedFeedback" :label="$t('feedbackCard.writeComment')" rows="5" auto-grow></v-textarea>
          <v-btn color="primary" @click="submitFeedback">{{ $t('feedbackCard.send') }}</v-btn>
        </div>
      </v-expand-transition>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="!editing" text @click="startEditing">{{ $t('feedbackCard.edit') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Flame } from 'lucide-vue-next';
import { supabase } from "../supabase";
import axios from 'axios'

// const date = ref('29.08.14:33');
const date = ref(new Date().toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }).replace(/\//g, '.')); // Format to 'dd.mm.yyyy hh:mm'
const rating = ref(0);
const editing = ref(false);
const editedFeedback = ref('');
const feedbackItems = ref([]);
const email_sent = ref(false);

// eslint-disable-next-line
const emit = defineEmits(['playAudio']); // Define an event to trigger audio playback

const playAudioAtTime = (timeString) => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  const totalSeconds = minutes * 60 + seconds;

  emit('playAudio', totalSeconds); // Emit the event with the timestamp in seconds
};

// eslint-disable-next-line
const props = defineProps({
  advisorName: {
    type: String,
    required: true
  },
  advisor_user_id: {
    type: String,
    required: true
  },
  callId: {
    type: Number,
    required: true
  }
});

const formatDate = (dateString) => {
  const isoDate = new Date(dateString); // Parse ISO string into a Date object
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
  const formattedDate = isoDate.toLocaleString('de-DE', options).replace(/\//g, '.');
  return `${props.advisorName}, ${formattedDate}`;
};


onMounted(async () => {
  try {
    const { data: callData, error } = await supabase
      .from('calls')
      .select('feedback')
      .eq('id', props.callId)
      .single();

    if (error) throw error;

    if (callData && callData.feedback) {
      date.value = callData.feedback.date;
      rating.value = callData.feedback.rating;
      editedFeedback.value = callData.feedback.text;
      feedbackItems.value = editedFeedback.value.split('\n').map(line => {
        const [time, ...textParts] = line.split(' ');
        return { time, text: textParts.join(' ') };
      });
    } else {
      editing.value = true;
      email_sent.value = false;
    }

  } catch (error) {
    console.error('Error fetching feedback:', error);
  }
});

const startEditing = () => {
  editing.value = true;
  editedFeedback.value = feedbackItems.value.map(item => `${item.time} ${item.text}`).join('\n');
};

const submitFeedback = async () => {
  const currentTimestamp = new Date().toISOString();
  const feedbackData = {
    date: currentTimestamp,
    rating: rating.value,
    advisor_user_id: props.advisor_user_id,
    text: editedFeedback.value
  };

  try {
    const { error } = await supabase
      .from('calls')
      .update({ feedback: feedbackData })
      .eq('id', props.callId);

    if (error) throw error;

    if (!feedbackItems.value.length && !email_sent.value) {
      await axios.post(
        `${process.env.VUE_APP_BACKEND_URL}/api/sendnotificationemail`,
        { call_id: props.callId },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      email_sent.value = true;
    }
    editing.value = false;

    const formattedDate = currentTimestamp.slice(0, 10).split('-').reverse().join('.') + ' ' + currentTimestamp.slice(11, 16);
    date.value = formattedDate;
    const newItems = editedFeedback.value.split('\n').map(line => {
      const [time, ...textParts] = line.split(' ');
      return { time, text: textParts.join(' ') };
    });
    feedbackItems.value = newItems;

  } catch (error) {
    console.error('Error submitting feedback:', error);
  }
};
</script>

<style scoped>
.v-rating {
  justify-content: flex-end;
}

.v-rating :deep(.v-icon) {
  opacity: 1 !important;
}

.v-rating :deep(.v-icon:hover) {
  color: rgba(101, 200, 205, 0.5) !important;
}


.flame {
  stroke: none;
  fill: white;
}

.rating {
  display: flex;
}


.rating-box {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  margin-left: 4px;
  cursor: pointer;
}

.rating-box.filled,
.rating-box:hover {
  background-color: #65C8CD;
}


.rating-box:hover .flame {
  opacity: 0.5;
}

.feedback-items {
  text-align: left;
}

.feedback-items>* {
  text-align: left;
}

.feedback-item {
  margin-bottom: 8px;
  text-align: left;
}

.feedback-edit textarea {
  width: 100%;
  margin-bottom: 8px;
}

.clickable-time {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
  /* Or any color you prefer */
}
</style>