<!-- Purpose of this view: upload audio files to the local directory 
  so that diarization etc can happen -->
<template>
  <v-snackbar v-model="showErrorSnackbar" color="error"> {{ errorMessage }} </v-snackbar>
  <v-card style="width: 100%;" class="card rounded-xl">
    <div class="upload-container">
      <v-snackbar v-show="jobStatus === 'in_progress'" color="info">{{ $t('upload.processing') }}</v-snackbar>
      <v-snackbar v-if="jobStatus === 'finished'" color="success">{{ $t('upload.finished') }}</v-snackbar>
      <v-snackbar v-model="successMessageVisible" color="success">{{ $t('upload.customerSaved') }}</v-snackbar>
      <v-breadcrumbs style="margin-left: 15px; text-align: left;">
        <v-breadcrumbs-item>{{ $t('upload.title1') }}</v-breadcrumbs-item>
      </v-breadcrumbs>

      <h1 style="text-align: left; margin-left:25px">{{ $t('upload.title') }}</h1>

      <div style="align-items: left; max-width: 300px; text-align: left;padding-left:15px">

        <v-btn variant=text color=primary v-if="!newCustomerVisible" @click="newCustomerVisible = true"
          style="align-items: right; margin-bottom: 15px; max-width: 300px; text-align: left;">{{
            $t('upload.addCustomer') }}</v-btn>
      </div>
      <div v-if="newCustomerVisible" style="align-self: flex-start; margin: 15px; text-align: left;">
        <v-text-field v-model="newCustomer.firstName" :label="$t('upload.firstName')" variant=solo
          style="max-width: 300px"></v-text-field>
        <v-text-field v-model="newCustomer.lastName" :label="$t('upload.lastName')" variant=solo
          style="max-width: 300px"></v-text-field>
        <div style="display: flex">
          <v-btn :disabled="!newCustomer.firstName || !newCustomer.lastName" color=primary @click="saveNewCustomer"
            style="margin: 10px" v-if=!newCustomerVisible.value>{{ $t('upload.save') }}</v-btn>
          <v-btn variant=tonal @click="newCustomerVisible = false" color='#9197B' style="margin: 10px"
            v-if=!newCustomerVisible.value>{{ $t('upload.cancel') }}</v-btn>
        </div>
      </div>
      <div style="display: flex; align-items: right;"> </div>

      <div class="content-container">
        <v-file-input :label="$t('upload.title')" id="uploadarea" accept="video/*,audio/*" :show-size="1000"
          :max-size="30145728" @change="handleFilesAdded" multiple outlined dense chips :truncate-length=25
          class="full-width"></v-file-input>
      </div>
      <div class="table-container full-width">
        <v-table>
          <thead>
            <tr>
              <th class="text-left">{{ $t('upload.fileName') }}</th>
              <th class="text-left">{{ $t('upload.advisor') }}</th>
              <th class="text-left">{{ $t('upload.conversationType') }}</th>
              <th class="text-left">{{ $t('upload.customer') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in files" :key="index">
              <td>{{ item.file.name }}</td>
              <td>
                <v-select v-if="advisors.length > 0" v-model="item.selectedAdvisor" :items="advisors"
                  item-title="full_advisor_name" id="advisor-id" item-value="id" :label="$t('upload.advisor2')"
                  variant="solo"></v-select>
              </td>
              <td>
                <v-select v-if="conversationTypes.length > 0" v-model="item.selectedConversationType"
                  :items="conversationTypes" item-title="name" variant="solo" item-value="associated_scorecard"
                  :label="$t('upload.conversationType')" class="flex-select form-item"></v-select>
              </td>
              <td>
                <v-select v-if="customers.length > 0" v-model="item.selectedCustomer" id="customer-id"
                  :items="customers" item-title="full_name" density="compact" variant="solo" item-value="id"
                  :label="$t('upload.customer2')" class="flex-select form-item" style="width: 300px;"></v-select>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
      <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="button-container">
      <v-progress-linear v-if="uploading" indeterminate color="primary" rounded class="loading-bar"></v-progress-linear>
      <p v-if="preProcessing">{{ preProcessingStatus }}</p>
      <p> {{ jobStatus }}</p>
      <v-btn class="me-2 text-none submit-btn" color="#5932ea" prepend-icon="mdi-export-variant" elevation="0"
        @click="submitFile">{{ $t('upload.title1') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { ref, onMounted, reactive, computed, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { supabase } from '../supabase.js';
import axios from 'axios';
import { useUser } from '../useUser';
import i18n from '@/i18n.js';

export default {
  setup() {
    const { user } = useUser();
    const router = useRouter();
    const route = useRoute();
    const selectedConversationType = ref(null);
    const selectedAdvisor = ref(null);
    const selectedCustomer = ref(null);
    const file = ref(null);
    const files = ref([]);
    const advisors = ref([]);
    const customers = ref([]);
    const conversationTypes = ref([]);
    const foldername = ref(null);
    const userId = ref(user.value ? user.value.id : null);
    const newCustomerVisible = ref(false);
    const newCustomer = reactive({ firstName: '', lastName: '', organisation: null });
    let organisationIds = [];
    let successMessageVisible = ref(false);
    const uploading = ref(false);
    const progress = ref(0);
    const tenant = computed(() => route.params.tenant);
    let showErrorSnackbar = ref(false);
    let errorMessage = ref("");
    const pollingIntervalId = ref(null);
    const jobStatus = ref(''); // 'pending', 'in_progress', 'finished', 'failed'
    const preProcessing = ref(false);
    const preProcessingStatus = ref('');
    const tableHeaders = ref([
      { text: 'Dateiname', value: 'name' },
      { text: 'Berater', value: 'advisor' },
      { text: 'Gesprächstyp', value: 'conversationType' },
      { text: 'Kunde', value: 'customer' },
    ]);

    onMounted(async () => {
      try {
        await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/api/send_language`,
          { value: localStorage.getItem('selectedLanguage') || 'de' },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (error) {
        console.error('Error sending language:', error);

      }
      // Fetching organisation IDs for the current user
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.value.id]);
      if (orgError) {
        showErrorSnackbar.value = true;
        errorMessage.value = orgError.message;
        return;
      }
      organisationIds = organisations.map(org => org.id);

      const { data, error } = await supabase
        .from('conversationtypes')
        .select('name, associated_scorecard')
        .in('associated_organisation_id', organisationIds);
      if (error) {
        showErrorSnackbar.value = true;
        errorMessage.value = "Gespräch konnte nicht geladen werden.";
      } else if (!data || data.length === 0) {
        showErrorSnackbar.value = true;
        errorMessage.value = "Scorecard konnte nicht geladen werden.";
      } else {
        const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));

        conversationTypes.value = sortedData.map(type => ({
          name: type.name,
          associated_scorecard: type.associated_scorecard
        }));
        selectedConversationType.value = conversationTypes.value[0]?.associated_scorecard;
      }

      // Fetching advisors based on organisation
      const { data: fetchedAdvisors, error: advisorError } = await supabase
        .from("advisors")
        .select("id, first_name, last_name")
        .in('organisation', organisationIds);
      if (advisorError) {
        console.error(advisorError);
        showErrorSnackbar.value = true;
        errorMessage.value = advisorError.message;
        return;
      }
      advisors.value = fetchedAdvisors
        .map(advisor => ({
          ...advisor,
          full_advisor_name: `${advisor.first_name} ${advisor.last_name}`
        }));

      // Fetching customers based on organisation
      const { data: fetchedCustomers, error: customerError } = await supabase
        .from("customers")
        .select("id, first_name, last_name")
        .in('organisation', organisationIds);
      if (customerError) {
        console.error(customerError);
        showErrorSnackbar.value = true;
        errorMessage.value = customerError.message;
        return;
      }
      customers.value = fetchedCustomers
        .map(customer => ({
          ...customer,
          full_name: `${customer.first_name} ${customer.last_name}`
        }));
    },
    );

    const startPollingJobStatus = async (jobId, callId) => {
      jobStatus.value = i18n.global.t('upload.analyzeRecording'); // Reset job status
      const pollIntervalMs = 70000; // Poll every 5 seconds

      pollingIntervalId.value = setInterval(async () => {
        try {
          const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/job-status/' + `${jobId}`)
          const status = response.data.status;
          jobStatus.value = status === i18n.global.t('upload.inProgress') ? i18n.global.t('upload.processing') : status;
          if (status === 'finished' || status === 'failed') {
            clearInterval(pollingIntervalId.value);
            pollingIntervalId.value = null;

            if (status === 'finished') {
              uploading.value = false;
              router.push(`/${tenant.value}/calls/${callId}`);
            }
          }
        } catch (error) {
          showErrorSnackbar.value = true;
          errorMessage.value = `Error polling job status: ${error.message}`;
          clearInterval(pollingIntervalId.value);
          pollingIntervalId.value = null;
        }
      }, pollIntervalMs);
    };

    // stopPolling is a function that is used to stop the polling process initiated by setInterval.
    // It checks if the pollingIntervalId is set (i.e., polling is currently in progress).
    // If it is, it clears the interval to stop further polling and sets the pollingIntervalId to null to indicate that polling has stopped.
    const stopPolling = () => {
      if (pollingIntervalId.value) {
        clearInterval(pollingIntervalId.value); // Clear the polling interval
        pollingIntervalId.value = null; // Reset the interval ID
      }
    };

    const handleFilesAdded = (event) => {
      if (event.target.files.length === 0) {
        files.value = []; // Clear the files array if no files are selected
        showErrorSnackbar.value = true;
        errorMessage.value = "Keine Dateien ausgewählt. Bitte wählen Sie Dateien zum Hochladen aus.";
      } else {
        files.value = Array.from(event.target.files).map(file => {
          const normalizedFileName = file.name.toLowerCase().replace(/[^a-z0-9]+/gi, ' ');

          // Fuzzy match for advisor, customer, and conversation type
          const foundAdvisor = advisors.value.find(a =>
            normalizedFileName.includes(a.full_advisor_name.toLowerCase().replace(/[^a-z0-9]+/gi, ' ')) ||
            normalizedFileName.includes(a.first_name.toLowerCase().replace(/[^a-z0-9]+/gi, ' '))
          ) || null;
          const foundCustomer = customers.value.find(c => normalizedFileName.includes(c.full_name.toLowerCase().replace(/[^a-z0-9]+/gi, ' '))) || null;
          const foundConversationType = conversationTypes.value.find(ct => normalizedFileName.includes(ct.name.toLowerCase().replace(/[^a-z0-9]+/gi, ' '))) || null;

          return {
            file: file,
            selectedAdvisor: foundAdvisor ? foundAdvisor.id : null,
            selectedConversationType: foundConversationType ? foundConversationType.associated_scorecard : null,
            selectedCustomer: foundCustomer ? foundCustomer.id : null,
          };
        });
      }
    };

    // Cleanup on component unmount
    // This lifecycle hook is used to clean up the polling process before the component is destroyed.
    // It ensures that the setInterval used for polling is cleared to prevent memory leaks and unnecessary operations.
    onBeforeUnmount(() => {
      stopPolling(); // Calls the stopPolling function to clear the interval set for polling
    });

    // Function to save a new customer to the database
    const saveNewCustomer = async () => {
      const { data, error } = await supabase
        .from('customers')
        .upsert([
          {
            first_name: newCustomer.firstName,
            last_name: newCustomer.lastName,
            organisation: organisationIds[0],
          },
        ])
        .select('id');
      const newCustomerData = {
        id: data[0].id,
        first_name: newCustomer.firstName,
        last_name: newCustomer.lastName,
        full_name: `${newCustomer.firstName} ${newCustomer.lastName}`,
      };
      customers.value.push(newCustomerData);
      // Set the selected customer to the new customer
      selectedCustomer.value = newCustomerData.id;

      // Clear fields again
      newCustomer.firstName = '';
      newCustomer.lastName = '';
      successMessageVisible.value = true;
      newCustomerVisible.value = false;
      if (error) {
        showErrorSnackbar.value = true;
        errorMessage.value = ('Kunden konnten nicht gespeichert werden');
      }
    };

    const submitFile = async () => {
      if (!files.value.some(fileItem => fileItem)) {
        showErrorSnackbar.value = true;
        errorMessage.value = "Bitte wähle mindestens eine Datei zum Hochladen aus.";
        return;
      }
      uploading.value = true;
      for (const fileItem of files.value) {
        await processAndUploadFile(fileItem);
      }
      uploading.value = false;
    };

    const processAndUploadFile = async (fileItem) => {
      let processedFile = fileItem.file;
      const formData = new FormData();
      const timestamp = new Date().toISOString().replace(/[^0-9]/g, "").slice(0, 14);
      const foldername = `${timestamp}_${fileItem.file.name}`;

      formData.append('file', fileItem.file);
      formData.append('customerId', fileItem.selectedCustomer?.toString() || '');
      formData.append('advisorId', fileItem.selectedAdvisor?.toString() || '');
      formData.append('associatedScorecard', fileItem.selectedConversationType?.toString() || '');
      formData.append('newFileName', fileItem.file.name);
      formData.append('foldername', foldername);
      formData.append('userId', userId.value.toString());
      formData.append('organisation', organisationIds[0].toString());

      if (processedFile.type.startsWith("video")) {
        preProcessing.value = true;
        preProcessingStatus.value = 'Datei wird vorbereitet...';

        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/videotoaudio`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            responseType: 'blob'
          });

          let newName = `${processedFile.name.split('.').slice(0, -1).join('.')}.mp3`;
          processedFile = new File([response.data], newName, { type: 'audio/mp3' });

          formData.delete('file');
          formData.append('file', processedFile);
          formData.set('newFileName', processedFile.name);
          formData.set('foldername', `${timestamp}_${processedFile.name}`);

        } catch (error) {
          console.error('Error converting video to audio:', error);
          showErrorSnackbar.value = true;
          errorMessage.value = "Fehler bei der Videokonvertierung.";
          uploading.value = false;
          preProcessing.value = false;
          return;
        } finally {
          preProcessing.value = false;
        }
      }

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/StoreLocally`, formData, {
          onUploadProgress: (progressEvent) => {
            progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });

        if (response.status === 200 && response.data.job_id) {
          await startPollingJobStatus(response.data.job_id, response.data.call_id);
        } else {
          showErrorSnackbar.value = true;
          errorMessage.value = "Ein unerwarteter Fehler ist aufgetreten.";
        }
      } catch (error) {
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      }
    };
    return {
      conversationTypes,
      file,
      foldername,
      userId,
      customers,
      selectedCustomer,
      selectedAdvisor,
      submitFile,
      newCustomerVisible,
      newCustomer,
      saveNewCustomer,
      successMessageVisible,
      uploading,
      progress,
      errorMessage,
      showErrorSnackbar,
      startPollingJobStatus,
      selectedConversationType,
      jobStatus,
      preProcessing,
      preProcessingStatus,
      handleFilesAdded,
      files,
      advisors,
      tableHeaders
    };
  }
}
</script>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.full-width {
  width: 100%;
  min-width: 300px;
}

.content-container,
.table-container {
  display: flex;
  justify-content: left;
  padding: 15px;
}

.button-container {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loading-bar {
  width: 300px;
  align-items: center;
  text-align: center;
}

.submit-btn {
  margin: 15px auto;
}

.form-container {
  flex: 1;
  margin-bottom: 15px;
  padding: 25px;
  align-items: left;
}

.form-container,
.uppy-container {
  flex: 1;
  padding: 25px;
}

.form-content {
  flex-direction: column;
  display: flex;
  width: 50%;
}

.form-item {
  width: calc(100% - 40px);
  max-width: 300px;
}

.flex-select {
  max-width: 300px;
  margin-left: 15px;
}

.flex-file {
  max-width: 300px;
  padding-left: 15px;
}

h1,
h2,
p {
  padding: 15px;
}

#uploadarea {
  width: 100%;
  height: 100%;
  /* Adjust height to match the form-container */
  margin-right: 30px;
}

.upload-container {
  width: 90%;
  align-items: flex-start;
  /* Align the Uppy Dashboard to the start of the container */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 25px;
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}
</style>