<!-- Simple password reset route with email inputfield -->
<template>
	<v-snackbar v-model="showErrorSnackbar" color="error">
		{{ errorMessage }}
	</v-snackbar>
	<v-row justify="center">
		<v-col class="d-flex align-center justify-center">
			<v-card class="view-card" rounded="xl">
				<form @submit.prevent="submit" class="form-content">
					<h2 style="padding: 20px;">{{ $t("passwordReset.title") }}</h2>
					<v-text-field v-model="email" label="E-mail" required density="compact" variant="solo"
						class="input-field form-item"></v-text-field>

					<v-btn @click="resetpassword" class="me-2 text-none" color="#5932ea" width="auto" rounded="4">
						{{ $t("passwordReset.button") }}
					</v-btn>
				</form>
			</v-card>
		</v-col>
	</v-row>
</template>

<script setup>
import { ref } from 'vue';
import { supabase } from '../supabase';
import { useForm } from 'vee-validate'

let email = ref('');
let showErrorSnackbar = ref(false);
let errorMessage = ref("");

async function resetpassword() {
	await supabase.auth.resetPasswordForEmail(email.value, {
		redirectTo: process.env.VUE_APP_BASE_ROUTE + '/update-password',
	}).catch((error) => {
		console.error(error);
		showErrorSnackbar.value = true;
		errorMessage.value = error.message;
	});
}

const { handleSubmit, errors } = useForm({
	validationSchema: {
		email(value) {
			if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true
			return 'Must be a valid e-mail.'
		},
	},
})

const submit = handleSubmit(values => {
	if (errors.email) {
		showErrorSnackbar.value = true;
		errorMessage.value = errors.email;
	} else {

		alert(JSON.stringify(values, null, 2))
	}
})
</script>

<style scoped>
.view-card {
	width: 400px;
	max-width: 90%;
	/* Prevent the card from being too wide on small screens */
	margin: 0 auto;
	/* Center the card */
	padding: 20px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	/* Add drop shadow */
}

.form-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.form-item {
	margin-bottom: 35px;
}

.input-field {
	width: calc(100% - 40px);
	box-shadow: 0px 0px 0px 1px #868FA0 16%;
}

h2 {
	padding: 20px;
}

v-text-field {
	width: 100%;
}

body,
html {
	height: 100%;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
